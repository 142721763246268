import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapView = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [center, setCenter] = useState({
    lat: 41.0082, // Default center (Istanbul)
    lng: 28.9784
  });

  useEffect(() => {
    // Fetch devices location data from the API
    const fetchDeviceLocations = async () => {
      try {
        setLoading(true);
        // Use the correct API endpoint with proper CORS configuration
        const response = await fetch('https://apik.sarivalas.com/api/devices', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log('Fetching from:', 'https://apik.sarivalas.com/api/devices');
        if (!response.ok) {
          throw new Error(`API error: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Fetched device data:', data);
        
        if (data && data.length > 0) {
          // Convert data to the format expected by the map
          const formattedDevices = data.map(device => ({
            deviceId: device.device_id,
            latitude: parseFloat(device.latitude),
            longitude: parseFloat(device.longitude),
            timestamp: device.timestamp
          }));
          
          setDevices(formattedDevices);
          
          // Update center to the first device's location
          setCenter({
            lat: formattedDevices[0].latitude,
            lng: formattedDevices[0].longitude
          });
          
          setError(null);
        } else {
          console.log('No device data received');
        }
      } catch (error) {
        console.error('Error fetching device locations:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDeviceLocations();
    // Update device positions more frequently (every 3 seconds)
    const interval = setInterval(fetchDeviceLocations, 3000);
    return () => clearInterval(interval);
  }, []);

  const mapContainerStyle = {
    width: '100%',
    height: '100%'
  };

  // Custom marker icon for better visibility
  const markerIcon = {
    path: window.google?.maps?.SymbolPath?.CIRCLE,
    fillColor: '#4285F4',
    fillOpacity: 1,
    scale: 8,
    strokeColor: '#FFFFFF',
    strokeWeight: 2
  };

  return (
    <div className="map-container">
      {loading && <div className="loading-indicator">Loading device locations...</div>}
      {error && <div className="error-message">Error: {error}</div>}
      
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={14} // Increased zoom level for better visibility
          center={center}
          options={{
            fullscreenControl: true,
            mapTypeControl: true,
            streetViewControl: true,
            zoomControl: true
          }}
        >
          {devices.map((device, index) => (
            <Marker
              key={device.deviceId || `device-${index}`}
              position={{ lat: device.latitude, lng: device.longitude }}
              title={`Device ${device.deviceId || index + 1}`}
              icon={markerIcon}
              animation={window.google?.maps?.Animation?.DROP}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapView;